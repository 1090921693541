import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = {
    containerCard: { borderLeft: 'solid 4px #f1831d', marginTop: '2em' },
    licensesCard: { wordWrap: 'break-word', wordBreak: 'break-all', fontSize: 12, color: 'gray', marginTop: '10px', padding: '5px' },
};

function availableLicensesMessage(availableLicenses) {
    return 'Highlight the desired TAC CREDIT license key(s) shown below and copy to your clipboard.  To obtain additional licenses, contact your Relationship Manager directly or call us at 800-246-4120.';
}

export default ({ licenses = {} }) => (
  <div>
  {licenses.length > 0
    ?
      <Card style={styles.containerCard}>
        <CardContent>
          <Typography variant="headline" component="h2">License Keys</Typography>
          <Typography component="p">{availableLicensesMessage(licenses.length)}</Typography>
          <List>
            {(licenses).map(record => (
              <Card style={styles.licensesCard } key={record.licensedUserNumber}>
                <Typography variant="subheading" component="h4">{`Licensed User ${record.licensedUserNumber}`}</Typography>
                <Typography variant="caption" component="h4">{record.licenseKey}</Typography>
              </Card>
              ),
            )}
          </List>
        </CardContent>
      </Card>
    : null
  }
  </div>
);
