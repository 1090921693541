import React from 'react';
import {
  translate,
  Create,
  DateField,
  Edit,
  LongTextInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  ReferenceInput
} from 'react-admin';
import License from '@material-ui/icons/EnhancedEncryption';


import getURLParameterByName from '../utils/getURLParameterByName';
import LicenseCreateActions from './LicenseCreateActions';
import LicenseEditActions from './LicenseEditActions';
import ClientNameLookupField from '../clients/ClientNameLookupField';

export const LicenseIcon = License;

const LicenseTitle = translate(({ record, translate }) => (<span>
  {translate('resources.licenses.page.editTitle')}
  </span>));

export const LicenseCreate = ({ ...props }) => (
  <Create {...props}
        title={<ClientNameLookupField clientId={getURLParameterByName('clientId', props.location.search)} />}
        actions={<LicenseCreateActions clientId={getURLParameterByName('clientId', props.location.search)} />}
        >
    <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`}>
    <TextInput source="licensedUserNumber" label="Licensed User Number" validate={required()} />
    <LongTextInput source="licenseKey" style={{ width: 900 }} validate={required()} />
    <TextField source="clientId" defaultValue={getURLParameterByName('clientId', props.location.search)}/>
    </SimpleForm>
  </Create>
);

export const LicenseEdit = ({ ...props }) => (
  <Edit title={<LicenseTitle />} {...props} actions={<LicenseEditActions clientId={getURLParameterByName('clientId', props.location.search)} />} >
    <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`} >
      <TextInput source="licensedUserNumber" label="Licensed User Number" validate={required()} />
      <LongTextInput source="licenseKey" style={{ width: 900 }} validate={required()} />
    </SimpleForm>
  </Edit>
);

/*
const LicenseDeleteTitle = translate(({ record, translate }) => (<span>
{translate('resources.licenses.page.delete')}&nbsp;
{record && `${record.licenseKey}` }
</span>));

export const LicenseDelete = (props) => <Delete {...props} title={<LicenseDeleteTitle />} />;
*/