import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CREATE, GET_LIST } from 'react-admin';

import { restClient } from '../localRest';
import SimpleCard from './SimpleCard';

const styles = {
  card: { borderLeft: 'solid 4px #f1831d', marginTop: '1em' },
};

class RecentDownloads extends React.Component {
  render() {
    return <Card style={styles.card}>
      <CardContent>
        <Typography variant="headline" component="h2">Recent Downloads</Typography>
        <Typography component="p">This dashboard component is available only to TAC CREDIT administrators.</Typography>
          <div>
            <div style={{ display: 'flex', flexDirection: 'col', flexWrap: 'wrap' }}>
              {this.props.recentDownloads.map(record => (
                  <div style={{ marginRight: 10, marginTop: 15 }}>
                    <SimpleCard record={record} />
                  </div>
              ),
              )}
            </div>
          </div>
      </CardContent>
    </Card>
  };
};

export default RecentDownloads;
