const getURLParameterByName = (name, url) => {
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results || !results[2]) {
        return '';
    }
    return results[2].toString();
};

export default getURLParameterByName;
