import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GET_ONE } from 'react-admin';
import { restClient } from '../localRest';

class ClientNameLookupField extends Component {
  state = {};

  componentDidMount() {
    return restClient(GET_ONE, 'clients', { id: this.props.clientId })
      .then(response => response.data) 
      .then(client => {
        this.setState( (state) => ({client: client}) )
    });
  }
    
  render() {
    const { client } = this.state;
    const name = client ? client.name : '';
    return (
      <span>{name}</span>
    );
  }
}

ClientNameLookupField.propTypes = {
  clientId: PropTypes.string,
};

export default ClientNameLookupField;
