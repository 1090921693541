import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import Icon from '@material-ui/icons/CloudDownload';

import getURLParameterByName from '../utils/getURLParameterByName';
import CustomReleaseCreateActions from './CustomReleaseCreateActions';
import CustomReleaseEditActions from './CustomReleaseEditActions';
import ClientNameLookupField from '../clients/ClientNameLookupField';

export const CustomReleaseIcon = Icon;

export const CustomReleaseList = props => (
  <List {...props} sort={{ field: 'version', order: 'DESC' }} perPage={25}>
  <Datagrid>
    <TextField source="version" />
    <TextField source="description" />
    <EditButton />
  </Datagrid>
  </List>

);

export const CustomReleaseCreate = props => (
  <Create {...props}
    title={<ClientNameLookupField clientId={getURLParameterByName('clientId', props.location.search)} />}
    actions={<CustomReleaseCreateActions clientId={getURLParameterByName('clientId', props.location.search)} />}
  >
  <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`} >
    <TextInput source="version" validate={required()} />
    <TextInput source="description" validate={required()} />
    <TextInput source="location" style={{ width:900 }} validate={required()} />
    <TextField source="clientId" defaultValue={getURLParameterByName('clientId', props.location.search)}/>
  </SimpleForm>
  </Create>
);

export const CustomReleaseEdit = props => (
  <Edit
    title="Edit Client Specific Release"
    {...props}
    actions={<CustomReleaseEditActions clientId={getURLParameterByName('clientId', props.location.search)} />}
  >
  <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`} >
    <TextInput source="version" validate={required()}/>
    <TextInput source="description" validate={required()} />
    <TextInput source="location" style={{ width:900 }} validate={required()} />
  </SimpleForm>
  </Edit>
);

