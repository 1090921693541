import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

const styles = {
  card: {
    minWidth: 275,
    backgroundColor: '#e6e6e6',
  },
  title: {
    fontSize: 13,
  },
  pos: {
    marginBottom: 0,
  },
};

function SimpleCard(props) {
  const { classes, record } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h4" gutterBottom>
          {record.clientName}
        </Typography>
        <div style={{ display: "flex" }}>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
            {`${record.detail}`}
          </Typography>
          <Typography className={classes.title} color="textPrimary" style={{ marginLeft: 15 }} gutterBottom>
            {moment(record.date).format('MMM DD hh:mm A')}
          </Typography>
        </div>
        <Typography className={classes.pos} color="textSecondary">
          { `${record.userEmail}` }
        </Typography>
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);