import React from 'react';
import { Login, LoginForm } from 'ra-ui-materialui';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    login: {
        main: {
          backgroundColor: '#1D8BF1',
        },
        card: {
            padding: '5px 0 15px 0',
        },
    },
    form: {
        button: {
            height: '3em',
        },
    },
};

const MyLoginForm = withStyles(styles.form)(LoginForm);

const MyLogin = (props) => (
    <Login
      loginForm={<MyLoginForm />}
      backgroundImage={null}
      {...props} />
);

export default withStyles(styles.login)(MyLogin);