import React from 'react';
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    DateField,
    CheckboxGroupInput,
} from 'react-admin';
import Icon from '@material-ui/icons/Subscriptions';
import moment from 'moment-timezone';

const ActivityFilter = (props) => (
    <Filter {...props}>
        <TextInput label="pos.search" source="q" alwaysOn />
        <CheckboxGroupInput alwaysOn label="Ignore Admin" source="scope" default={{ id: 'ignoreAdmin' }} choices={[
            { id: 'ignoreAdmin', name: '' },
        ]} />
    </Filter>
);

export const ActivityIcon = Icon;

const FormattedDate = (props) => <div style={{ width: 125 }} >{moment(props.record.date).format('MM/DD/YYYY hh:mm A')}</div> ;


export const ActivityList = props => (
        <List {...props} filters={<ActivityFilter/>} title="Activities" sort={{ field: 'date', order: 'DESC' }} perPage={50} bulkActions={false} >
        <Datagrid>
            <FormattedDate label="Date" />
            <TextField source="clientName" label="Client" style={{ width: 200 }}  />            
            <TextField source="activityType" label="Activity" />            
            <TextField source="detail" label="Detail" />            
            <TextField source="userName" label="User Name" style={{ width: 150 }} />            
            <TextField source="userEmail" label="Email" />         
            <TextField source="extraDetail" label="Additional Detail" />            
        </Datagrid>
    </List>

);


