import React from 'react';
import PropTypes from 'prop-types';
import ClientReturnButton from '../clients/ClientReturnButton';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const CustomReleaseEditActions = ({ ...props }) => (
    <div style={cardActionStyle}>
        <ClientReturnButton clientId={props.clientId} />
    </div>
);

CustomReleaseEditActions.propTypes = {
    clientId: PropTypes.string,
    basePath: PropTypes.string,
    data: PropTypes.object,
};

export default CustomReleaseEditActions;
