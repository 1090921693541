import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Title, WithPermissions } from 'react-admin';

const styles = {
  card: { marginTop: '1em' },
  actions: { float: 'right' }
};

function firstName() {
  const systemUserRecord = localStorage.getItem('systemUserRecord');
  let firstName = '';
  if (systemUserRecord !== null) {
    const systemUserObject = JSON.parse(systemUserRecord);
    firstName = systemUserObject.firstName;
  }
  return firstName;
}

const Welcome = (props) => (
  <Card className={props.classes.card}>
    <Title title={props.title} />
    <CardContent>
      <Typography variant="headline" component="h2">
        {`Welcome ${firstName()}`}
      </Typography>
      <Typography component="p">
        From this portal, you can download the current installation of TAC CREDIT and obtain your organization's license keys.
      </Typography>
    </CardContent>
    <CardActions className={props.classes.actions}>
      <Button href="http://taccredit.com/" >
        <HomeIcon />TAC CREDIT Website
      </Button>
    </CardActions>
  </Card>
);

export default withStyles(styles)(Welcome);