import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  translate,
  Create,
  Delete,
  Edit,
  required,
  SimpleForm,
  TextField,
  TextInput,
  List,
  Datagrid,
  ReferenceField,
  Filter,
  EditButton,
} from 'react-admin';

import Icon from '@material-ui/icons/Person';

import getURLParameterByName from '../utils/getURLParameterByName';
import SystemUserCreateActions from './SystemUserCreateActions';
import SystemUserEditActions from './SystemUserEditActions';
import ClientNameLookupField from '../clients/ClientNameLookupField';

export const SystemUserIcon = Icon;

const SystemUserFilter = (props) => (
  <Filter {...props}>
  <TextInput label="pos.search" source="q" alwaysOn />
  </Filter>
);
const SystemUserTitle = ({ record = {} }) => <span>{record.firstName} {record.lastName}</span>;

export const SystemUserList = (props) => (
  <List {...props} filters={<SystemUserFilter />} sort={{ field: 'lastName', order: 'ASC' }} perPage={25}>
    <Datagrid>
      <SystemUserTitle label="Name" />
      <ReferenceField label="Client" source="clientId" reference="clients" linkType={false} >
      <TextField source="name" />
      </ReferenceField>   
      <TextField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

export const SystemUserCreate = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props}
      title={<ClientNameLookupField clientId={getURLParameterByName('clientId', props.location.search)} /> }
      actions={<SystemUserCreateActions clientId={getURLParameterByName('clientId', props.location.search)} />}
    >
    <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`}>
      <TextInput source="firstName" style={{ width: 200 }} formClassName={classes.inlineBlock}  validate={required()} />
      <TextInput source="lastName" style={{ marginLeft:10, width: 200 }} formClassName={classes.inlineBlock} validate={required()} />
      <TextInput source="email" validate={required()} style={{ width: 350 }}  />
      <TextInput source="password" label="Password" validate={required()} style={{ width: 150 }} />
      <TextField source="clientId" defaultValue={getURLParameterByName('clientId', props.location.search)} />
      <TextField source="role" label="" defaultValue="admin" />
    </SimpleForm>
  </Create>
));

export const SystemUserEdit = withStyles(styles)(({ classes, ...props }) => (
  <Edit title={<SystemUserTitle />} {...props} actions={<SystemUserEditActions clientId={getURLParameterByName('clientId', props.location.search)} />} >
  <SimpleForm redirect={`/clients/${getURLParameterByName('clientId', props.location.search)}`} >
    <TextInput source="firstName" style={{ marginLeft:10, width: 200 }} formClassName={classes.inlineBlock}  validate={required()} />
    <TextInput source="lastName" style={{ marginLeft:10, width: 200 }} formClassName={classes.inlineBlock} validate={required()} />
    <TextInput source="email" validate={required()} style={{ width: 350 }}  />
    <TextInput source="password" label="Enter a new password to change exisiting one" style={{ width: 350 }} />
  </SimpleForm>
  </Edit>
));

/*
const SystemUserDeleteTitle = translate(({ record, translate }) => (
  <span>
  {translate('resources.systemUsers.page.delete')}&nbsp;
  {record && `${record.firstName} ${record.lastName}`}
  </span>));

export const SystemUserDelete = (props) => <Delete {...props} title={<SystemUserDeleteTitle />} />;
*/