export default {
    incorrect_email_or_password: 'Incorrect email or password',
    pos: {
        search: 'Search',
    },
    resources: {
        clients: {
            name: 'Client |||| Clients',
            fields: {
                nb_systemUsers: 'Users',
                nb_licenses: 'Licenses',
                features: 'Features',
                latest_download_gte: 'Downloaded Since',
                name: 'Name',
            },
            tabs: {
                identity: 'Client Info',
                systemUsers: 'Portal Users',
                downloads: 'Downloads',
                licenses: 'Licenses',
            },
            page: {
                delete: 'Delete Client',
            },
            action: {
                createLicense: 'Create License',
            },
        },
        systemUsers: {
            name: 'Portal User |||| Portal Users',
            fields: {
                clientId: '',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                lastName: 'Last Name',
                firstName: 'First Name',
            },
            page: {
                delete: 'Delete Portal User',
            },
        },
        releases: {
            name: 'Release |||| Releases',
            fields: {
                categoryId: 'Category',
                height_gte: 'Min height',
            },
        },
        downloads: {
            name: 'Download |||| Downloads',
            fields: {
                clientId: '',
                systemUserId: 'SystemUser',
                releaseId: 'Release',
                date_gte: 'Downloaded since',
                date_lte: 'Downloaded before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Download approved',
                approved_error: 'Error: Download not approved',
                rejected_success: 'Download rejected',
                rejected_error: 'Error: Download not rejected',
            },
        },
        licenses: {
            name: 'License |||| Licenses',
            fields: {
                clientId: '',
                licenseKey: 'License Key',
                generated: 'Generated',
            },
            createLicense: 'New License',
            page: {
                delete: 'Delete License: ',
                editTitle: 'License',
            },
        },
        features: {
            name: 'Features',
            fields: {
                clients: 'Clients',
                name: 'Name',
            },
            data: {
                projections: 'TAC Projections',
                full_reports: 'Full Reports',
                rental_property: 'Rental Property Menu',
                tax_form_consolidation: 'Tax Form Consolidation',
                risk_tools: 'Risk Tools Menu',
            },
        },
    },
};
