import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GET_LIST } from 'react-admin';
import { restClient } from '../localRest';

class ClientLicenseCount extends Component {
  state = {};

  componentDidMount() {
    return restClient(GET_LIST, 'licenses', {
      filter: { clientId: this.props.record.id },
      pagination: { page: 1, perPage: 100 },
  })
    .then(response => response.data) 
    .then(licenses => {
      this.setState( (state) => ({ clientLicenseCount: licenses.reduce(clientLicenseCount => ++clientLicenseCount, 0) }) );
      });
  }
    
  render() {
    const { clientLicenseCount } = this.state;
    const count = clientLicenseCount ? clientLicenseCount: '';
    return (
      <span>{count}</span>
    );
  }
}

ClientLicenseCount.propTypes = {
  props: PropTypes.object,
};

export default ClientLicenseCount;
