// This source was from https://github.com/leperone/aor-parseserver-client  Then modified to match the sequelize rest formats.
import { RestClient, FUNCTION } from './restClient'
import AuthClient from './authClient'

const AUTH_KEEP_ALIVE = 'AUTH_KEEP_ALIVE';

const hostURL = process.env.REACT_APP_HOST_URL || 'https://data.taccreditportal.com'
const parseConfig = { URL : hostURL };

const restClient = RestClient(parseConfig);
const authClient = AuthClient(parseConfig);

export { restClient, authClient, AUTH_KEEP_ALIVE, FUNCTION }
