import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux';

class CreateClientSublistButton extends Component {
  handleCreate = () => {
    this.props.push(this.props.url);
    }

  render() {
    return (<Button color="primary" size="small" onClick={this.handleCreate}>{this.props.icon}{this.props.label}</Button>
    );
  }
}

CreateClientSublistButton.propTypes = {
  record: PropTypes.object,
  push: PropTypes.func,
  icon: PropTypes.element,
  label: PropTypes.string,
  url: PropTypes.string,
};

export default connect(null, { push: pushAction })(CreateClientSublistButton);
