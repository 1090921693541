import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';

import { ClientIcon } from '../clients';

const LinkToRelatedClients = ({ feature, translate }) => (
    <Button
        primary
        label={translate('resources.features.fields.clients')}
        icon={<ClientIcon />}
        containerelement={<Link to={{
            pathname: "/clients",
            search: stringify({ page: 1, perPage: 25, filter: JSON.stringify({ features: feature }) }),
        }} />}
    />
);

export default translate(LinkToRelatedClients);
