import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    translate,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Delete,
    Create,
    Edit,
    Filter,
    FormTab,
    List,
    LongTextInput,
    NullableBooleanInput,
    NumberField,
    PropTypes,
    ReferenceManyField,
    ChipField,
    SingleFieldList,
    required,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    validate,
    EditButton
} from 'react-admin';
import Icon from '@material-ui/icons/Business';
import Chip from '@material-ui/core/Chip';

import ClientNameField from './ClientNameField';
import ClientEditActions from './ClientEditActions';
import EditClientSublistButton from './EditClientSublistButton';
import ClientLicenseCount from './ClientLicenseCount';

import {cyan500} from '@material-ui/core/colors';

export const ClientIcon = Icon;

const ClientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="pos.search" source="q" alwaysOn />
    </Filter>
);

const colored = WrappedComponent => props => props.record[props.source] > 500 ?
    <span style={{ color: 'red' }}><WrappedComponent {...props} /></span> :
    <WrappedComponent {...props} />;

const ColoredNumberField = colored(NumberField);
ColoredNumberField.defaultProps = NumberField.defaultProps;

const SystemUserChip = ({ record = {}, elStyle={ margin: 1, backgroundColor: 'white' } }) => <Chip style={elStyle} label={`${record.firstName} ${record.lastName}`} /> ;

const CustomReleaseChip = ({ record = {}, elStyle={ margin: 1, backgroundColor: 'white' } }) => <Chip style={elStyle} label={record.version} /> ;

export const ClientList = (props) => (
    <List {...props} title="Clients" filters={<ClientFilter />} sort={{ field: 'name', order: 'ASC' }} perPage={50} bulkActions={false} >
        <Datagrid>
            <ClientNameField />
            <TextField source="users" label="Users" />
            <TextField source="customReleases" label="Custom Releases" />
            <TextField source="totalLicenses" label="# Licenses" />
            <DateField source="licenseExpiration" label="Lic Exp" type="date" />
            <EditButton />
        </Datagrid>
    </List>

);

const ClientTitle = ({ record }) => record ? <ClientNameField record={record} size={32} /> : null;

export const ClientCreate = (props) => (
    <Create title={<ClientTitle />} {...props}>
        <TabbedForm>
            <FormTab label="resources.clients.tabs.identity">
                <TextInput source="name" style={{ display: 'inline-block' }} validate={required()} />
                <LongTextInput source="address" style={{ maxWidth: 544 }} />
                <TextInput source="city" style={{ display: 'inline-block' }} options={{ fullWidth: true }} />
                <TextInput type="state" source="state" validation={{ state: true }} style={{ display: 'inline-block', marginLeft: 32, width: 30 }} />
                <TextInput source="zipCode" style={{ display: 'inline-block', marginLeft: 32 }} />
                <DateInput source="licenseExpiration" label = "License Expiration" style={{ width: 130 }} />
            </FormTab>
        </TabbedForm>
    </Create>
);
const SystemUserTitle = ({ record = {} }) => <span>{record.firstName} {record.lastName}</span>;

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    section: { marginTop: 25, marginBottom: 0, fontWeight: 400, fontSize: 18 },
};

export const ClientEdit = withStyles(styles)(({ classes, ...props }) => (
    <Edit title={<ClientTitle />} {...props} actions={<ClientEditActions data={props.data} />} >
        <SimpleForm>
                <TextInput source="name" style={{ marginLeft: 20 }} />
                <LongTextInput source="address" style={{ maxWidth: 544, marginLeft: 20 }} />
                <TextInput source="city" style={{ marginLeft:20, width: 250 }} formClassName={classes.inlineBlock} />
                <TextInput type="state" source="state" style={{ marginLeft: 10, width: 50 }} formClassName={classes.inlineBlock} />
                <TextInput source="zipCode" style={{ marginLeft: 10, width: 100  }} formClassName={classes.inlineBlock} />
                <DateInput source="licenseExpiration" label="License Expiration" style={{ marginLeft: 20, width: 150 }} formClassName={classes.inlineBlock} />
                <div style={styles.section}>Portal Users</div>
                <ReferenceManyField addLabel={false} reference="systemUsers" target="clientId" style={{ marginTop:'30px' }} >
                    <Datagrid>
                        <SystemUserTitle label="Name" headerStyle={{ textAlign: 'left', color:'black', fontWeight:'bold' }} style={{ width: 200 }}  />
                        <TextField source="email" label="Email" />
                        <EditClientSublistButton />
                        
                    </Datagrid>
                </ReferenceManyField>
                <div style={styles.section}>Licenses</div>
                <ReferenceManyField addLabel={false} reference="licenses" target="clientId" style={{ marginTop:'30px' }} sort={{ field: "licensedUserNumber", order: "ASC" }} >
                    <Datagrid >
                        <TextField source="licensedUserNumber" label="License #" headerstyle={{ textAlign: 'left', color:'black'}} />
                        <TextField source="licenseKey" label="License Code" style={{ fontSize: 12 }} />
                        <EditClientSublistButton />
                    </Datagrid>
                </ReferenceManyField>
                <div style={styles.section}>Custom Releases</div>
                <ReferenceManyField addLabel={false} reference="customReleases" target="clientId" style={{ marginTop:'30px' }}  sort={{ field: "version", order: "DESC" }} >
                    <Datagrid >
                        <TextField source="version" label="Version" headerstyle={{ textAlign: 'left', color:'black'}} style={{ width: 120 }} />
                        <TextField source="description" label="Description" style={{ width: 200 }} />
                        <TextField source="location" label="Location" style={{ fontSize: 12 }} />
                        <EditClientSublistButton />
                    </Datagrid>
                </ReferenceManyField>

        </SimpleForm>
    </Edit>
));

/*
ClientEdit.propTypes = {
    record: PropTypes.string,
};
*/

/*
const ClientDeleteTitle = translate(({ record, translate }) => <span>
    {translate('resources.clients.page.delete')}&nbsp;
    {record && `${record.name}`}
</span>);

export const ClientDelete = (props) => <Delete {...props} title={<ClientDeleteTitle />} />;
*/
