import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CREATE, GET_LIST } from 'react-admin';

import { restClient } from '../localRest';

const styles = {
  card: { borderLeft: 'solid 4px #f1831d', marginTop: '1em' },
  secondary: { color: 'blue', fontWeight: '500' },
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
  }

class AvailableReleases extends React.Component {
  downloadClick = (record, onContextMenu) => {
    const thisContext = this;
    // On both click and right-click events we want to capture a download activity.
    const systemUserRecord = localStorage.getItem('systemUserRecord');
    const systemUserObject = JSON.parse(systemUserRecord);

    // Get the download activity id, then write the download activity
    return restClient(GET_LIST, 'activityTypes', { filter: { name: 'Download'} })
      .then(response => response.data) 
      .then(activityType => {
        restClient(CREATE, 'activities', { data: {
          systemUserId: systemUserObject.id,
          clientId: systemUserObject.clientId,
          activityTypeId: activityType[0].id,
          detail: record.version,
          extraDetail: record.location,
        } }).then(() => {
          thisContext.props.downloadRequested(); // Cause the dashboard to redraw to pick up the new download
          if(onContextMenu === false) {
            // Force a download if not a right click (context menu)
            thisContext.refs.download.click();
          }
        })
      })
      .catch(error => {
        const a = error;
      });

  }
  render() {
    return <Card style={styles.card}>
      <CardContent>
        <Typography variant="headline" component="h2">TAC CREDIT Installation Software</Typography>
        <List>
            {this.props.releases.map(record => (
              <div>
                <ListItemLink download="" onClick={this.downloadClick.bind(this, record, false)} onContextMenu={this.downloadClick.bind(this, record, true)} >
                  <ListItem key={record.version}>
                    <ListItemText
                      primary={`Version ${record.version} - ${record.description}`}
                      secondary='Click here to download' classes={{ secondary: this.props.classes.secondary }}
                    />
                  </ListItem>
                </ListItemLink>
                <a
                  ref="download"
                  href={record.location}
                >
                </a>
              </div>
            ),
            )}
        </List>
      </CardContent>
    </Card>
  };
};

AvailableReleases.props = {
  downloadRequested: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AvailableReleases);
