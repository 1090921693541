import React from 'react';
import PropTypes from 'prop-types';
import { CardActions } from '@material-ui/core/Card';
import { ListButton, DeleteButton } from 'react-admin';
import CreateClientSublistButton from './CreateClientSublistButton';
import Person from '@material-ui/icons/Person';
import License from '@material-ui/icons/EnhancedEncryption';
import { SystemUserIcon } from '../systemUsers';
import { LicenseIcon } from '../licenses';
import { ReleaseIcon } from '../releases';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

class ClientEditActions extends React.Component {
  render() {
      const id = this.props.data.id;
      return (
        <div style={cardActionStyle}>
            <CreateClientSublistButton icon={<ReleaseIcon/>} label=" New Custom Release" url={`/CustomReleases/create?clientId=${id}`} />
            <CreateClientSublistButton icon={<LicenseIcon/>} label="New License" url={`/licenses/create?clientId=${id}`} />
            <CreateClientSublistButton icon={<SystemUserIcon/>} label="New Portal User" url={`/systemUsers/create?clientId=${id}`} />
            <ListButton basePath={this.props.basePath} />
        </div>
      );
  }
};
ClientEditActions.propTypes = {
    data: PropTypes.object,
    basePath: PropTypes.string,
};

export default ClientEditActions;
