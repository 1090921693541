import React from 'react';
import PropTypes from 'prop-types';
import ClientReturnButton from '../clients/ClientReturnButton';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const CustomReleaseCreateActions = ({ ...props }) => (
  <div style={cardActionStyle}>
    <ClientReturnButton clientId={props.clientId} />
  </div>
);

CustomReleaseCreateActions.propTypes = {
  clientId: PropTypes.string,
};

export default CustomReleaseCreateActions;
