// Menu.js
import React from 'react';
import { MenuItemLink, WithPermissions, DashboardMenuItem, logout } from 'react-admin';
import Divider from '@material-ui/core/Divider';

import { ClientIcon } from './clients';
import { ReleaseIcon } from './releases';
import { ActivityIcon } from './activities';
// import { SystemUserIcon } from './systemUsers';

export const Menu = ({ onMenuClick, logout }) => (
  <div>
    <DashboardMenuItem onClick={onMenuClick} />
    <WithPermissions>
    {({ permissions }) => permissions === 'admin'
      ? <div>
        <MenuItemLink to="/clients" primaryText="Clients" leftIcon={<ClientIcon />} onClick={onMenuClick} />
        <MenuItemLink to="/releases" primaryText="Standard Releases" leftIcon={<ReleaseIcon />} onClick={onMenuClick} />
        <MenuItemLink to="/activitiesView" primaryText="Activity" leftIcon={<ActivityIcon />} onClick={onMenuClick} />
      </div>
      : <div />
    }
    </WithPermissions>
    <Divider/>
    {logout}
  </div>
);

export default Menu;
