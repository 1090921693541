import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ContentCreate from '@material-ui/icons/Create';

const EditClientSublistButton = ({ basePath = '', record = {} }) => (
    <IconButton
        to={`${basePath}/${record.id}/?clientId=${record.clientId}`}
        style={{ overflow: 'inherit' }}
        color="primary"
        component={Link}
    >
        <ContentCreate/>
    </IconButton>
);

EditClientSublistButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
};

EditClientSublistButton.defaultProps = {
    style: { padding: 0 },
};

export default EditClientSublistButton;
