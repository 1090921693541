import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';

class ClientReturnButton extends Component {
    handleClick = () => {
        this.props.push(`/clients/${this.props.clientId}`);
    }
    render() {
        return <Button color="primary" onClick={this.handleClick}><ArrowBack/>RETURN</Button>
    };
}

ClientReturnButton.propTypes = {
    clientId: PropTypes.string,
};

ClientReturnButton.defaultProps = {
    style: { padding: 0 },
    push: PropTypes.func,
};

export default connect(null, { push: pushAction })(ClientReturnButton);
