import React from 'react';
import { Card } from '@material-ui/core/Card';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from '@material-ui/core/Table';
import { translate, ViewTitle } from 'react-admin';

import LinkToRelatedClients from './LinkToRelatedClients';
import features from './data';

export default translate(({ translate }) => (
    <Card>
        <ViewTitle title={translate('resources.features.name')} />
        <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                    <TableHeaderColumn>{translate('resources.features.fields.name')}</TableHeaderColumn>
                    <TableHeaderColumn />
                </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
                {features.map(feature => (
                    <TableRow key={feature.id}>
                        <TableRowColumn>{translate(feature.name)}</TableRowColumn>
                        <TableRowColumn>
                            <LinkToRelatedClients feature={feature.id} />
                        </TableRowColumn>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Card>
));
