import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

export const FUNCTION = 'FUNCTION';

/*
const queryParameters = data => Object.keys(data)
    .map(key => [key, data[key]].map(encodeURIComponent).join('='))
    .join('&');
*/
// Rewritten to support group choices for a field: changed from outputting "grade=A,B" to "grade=A&grade=B"
const queryParameters = data => Object.keys(data).map((key) => {
    let dataValue = [];
    if(Array.isArray(data[key]))
      dataValue = data[key];
    else
      dataValue.push(data[key]);
    return dataValue.map(element => [key, element].join('=')).join('&');
  }).join('&');

function filterQuery(obj) {
	let result = {};
	Object.keys(obj).forEach(function(x){
		result[x] = obj[x];
	});
	if (Object.keys(result).length >0)
		return JSON.stringify(result);
	return null;
}

export const RestClient = (parseConfig, httpClient = fetchUtils.fetchJson) => {
	
	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The REST request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertRESTRequestToHTTP = (type, resource, params) => {
		const readTypes = ['GET_LIST', 'GET_MANY', 'GET_MANY_REFERENCE']
		let url = '';
		// Use the client view for reading
		if(resource === 'clients' && readTypes.includes(type)) {
			resource = 'clientsView';
		}
	    
	    let options = {};
		const token = localStorage.getItem('accessToken');
		/*
		options.headers = {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		};
		*/
		options.user = { authenticated: true, token: `Bearer ${token}` };

		const cacheBlocker = `&cb${new Date}`;

	    switch (type) {
		case GET_MANY_REFERENCE:
	    case GET_LIST: {
	      const page = (params.pagination && params.pagination.page != null) ? params.pagination.page : 1;
			  const perPage = (params.pagination && params.pagination.perPage != null) ? params.pagination.perPage : 10;
			  const field = (params.sort && params.sort.field != null) ? params.sort.field : "createdAt";
			  const order = (params.sort && params.sort.order != null) ? params.sort.order : "ASC";
			  const filter = (params.filter != null) ? filterQuery(params.filter) : null;
			  const include = (params.include != null) ? params.include.replace(/\s/g, "") : null;
        const query = {
          offset: (page -1) * perPage,
          count: perPage,
          sort: (order === "DESC" ? "-"+field : field),
        };
        if (include != null){
          query.include = include;
		}
		let where = type === GET_MANY_REFERENCE ? `&${[params.target]}=${params.id}` : ''; // Force to use reference key
        if (filter != null){
			where = where + `&${queryParameters(JSON.parse(filter))}`;
		}
		const queryParams = queryParameters(query);
        url = `${parseConfig.URL}/${resource}?${queryParams}${where}${cacheBlocker}`;
        break;
	    }
	    case GET_ONE:
	        url = `${parseConfig.URL}/${resource}/${params.id}${cacheBlocker}`;
	        break;
	    case UPDATE:
	        url = `${parseConfig.URL}/${resource}/${params.id}`;
	        options.method = 'PUT';
	        delete params.data.id;
	        delete params.data.createdAt;
	        delete params.data.updatedAt;
	        options.body = JSON.stringify(params.data);
	        break;
	    case CREATE:
	        url = `${parseConfig.URL}/${resource}`;
          options.method = 'POST';
	        options.body = JSON.stringify(params.data);
	        break;
	    case DELETE:
	        url = `${parseConfig.URL}/${resource}/${params.id}`;
	        options.method = 'DELETE';
	        break;
	    case FUNCTION:
			url = `${parseConfig.URL}/${resource}`;
	        options.method = 'POST';
	        options.body = JSON.stringify(params.data);
	        break;
	    default:
	        throw new Error(`Unsupported fetch action type ${type}`);
	    }
	    return { url, options };
	};
	
	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The REST request params, depending on the type
	 * @returns {Object} REST response
	 */
	const convertHTTPResponseToREST = (response, type, resource, params) => {
	    const { headers, json } = response;
	    switch (type) {
	    case GET_LIST:
      case GET_MANY_REFERENCE:

	  if (!headers.has('content-range')) {
		  throw new Error('The Content-Range header is missing in the HTTP Response. The REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
        }
		const contentRange = headers.get('content-range');
        const maxInPage = parseInt(contentRange.split('/')[0].split('-').pop(), 10);

        return {
            data: json.map(x => x),
            total: parseInt(contentRange.split('/').pop(), 10) || maxInPage,
        };
	    case CREATE:
	    case GET_ONE:
	    case UPDATE:
	    case DELETE:
			return { 
				data: { ...json, id: json.id },
			};			
	    case GET_MANY:
	    	return {
	            data : json.map(x => ({...x, id: x.objectId})),
	        };
	    default:
	        return json;
	    }
	};
	
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
		//  finale-rest api doesn't handle WHERE IN requests, so we fallback to calling GET_ONE n times instead
		if (type === GET_MANY) {
			let options = {};
			const token = localStorage.getItem('accessToken');
			options.user = { authenticated: true, token: `Bearer ${token}` };
			return Promise.all(
				params.ids.map(id => httpClient(`${parseConfig.URL}/${resource}/${id}`, options))
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}
        const { url, options } = convertRESTRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponseToREST(response, type, resource, params)
        );
    };
}
