import React from 'react';
import {
    translate,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import Icon from '@material-ui/icons/CloudDownload';

export const ReleaseIcon = Icon;

export const ReleaseList = props => (
        <List {...props} sort={{ field: 'version', order: 'DESC' }} perPage={25} bulkActions={false} >
        <Datagrid>
            <TextField source="version" />
            <TextField source="description" />
            <EditButton />
        </Datagrid>
    </List>

);

export const ReleaseCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="version" validation={{ required: true }} />
            <TextInput source="description" validation={{ required: true }} />
            <TextInput source="location" style={{ width:900 }} validation={{ required: true }} />
        </SimpleForm>
    </Create>
);

export const ReleaseEdit = props => (
    <Edit {...props} >
        <SimpleForm>
            <TextInput source="version" validation={{ required: true }} />
            <TextInput source="description" validation={{ required: true }} />
            <TextInput source="location" style={{ width: 900 }} validation={{ required: true }} />
            </SimpleForm>
    </Edit>
);

