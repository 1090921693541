import 'airbnb-js-shims';
import React from 'react';
import { render } from 'react-dom';
import { Admin, Resource, AppBar, Layout } from 'react-admin';
import JssProvider from 'react-jss/lib/JssProvider';

import enMessages from './i18n';
import customRoutes from './routes';
import LoginPage from './LoginPage';
import { Dashboard } from './dashboard';
import AppMenu from './AppMenu';

import { authClient, AUTH_KEEP_ALIVE, restClient } from './localRest';
import { ClientList, ClientCreate, ClientEdit, ClientIcon } from './clients';
import { ReleaseList, ReleaseCreate, ReleaseEdit, ReleaseIcon } from './releases';
import { CustomReleaseList, CustomReleaseCreate, CustomReleaseEdit, CustomReleaseIcon } from './customReleases';
import { SystemUserList, SystemUserCreate, SystemUserEdit, SystemUserIcon } from './systemUsers';
import { LicenseEdit, LicenseCreate } from './licenses';
import { ActivityList, ActivityIcon } from './activities';
import { generateClassName } from './generateClassName';

class App extends React.Component {
  componentDidMount() {
    let havehadRecentActivity = false;
    const inactivityTime = (innactivityThreshhold) => {
      let t;
      
      const logout = () => {
        havehadRecentActivity = false;
        localStorage.setItem('refreshToken', ' '); // Force a logout on the next AUTH_KEEP_ALIVE request
        localStorage.removeItem('accessToken');
        window.location.reload(); // Force a logout/login
      }
      
      // The user is active, so reset to watch for another inactivity period
      const resetTimer = () => {
        clearTimeout(t);
        havehadRecentActivity = true;
        t = setTimeout(logout, innactivityThreshhold);
      }
      window.onload = resetTimer;
      // DOM Events that indicate the user is active in the window
      document.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer; // touchscreen presses
      document.ontouchstart = resetTimer;
      document.onclick = resetTimer;     // touchpad clicks
      document.onscroll = resetTimer;    // scrolling with arrow keys
      document.onkeypress = resetTimer;
      resetTimer();
    }

    // Every so often we'll refresh the access token so that it doesn't go stale and timeout on us.  However,
    // don't refresh is there hasn't been user activity.
    const keepAlive = (keepAliveThreshold) => {
      let t;
    
      const keepAliveIfRecentActivity = () => {
        if(havehadRecentActivity) {
          return new Promise((resolve) => {
            return authClient(AUTH_KEEP_ALIVE).then(() => {
              return resetTimer();
            })
            .catch(() => {
              return resetTimer();
            });
          });
        }
        else {
          resetTimer();
        }
      }
    
      const resetTimer = () => {
          clearTimeout(t);
          t = setTimeout(keepAliveIfRecentActivity, keepAliveThreshold);
      }

      resetTimer();
    }

    // Start the timers up.  The keep alive time must be less than the inactivity time.
    // The inactivity time is how long we want the user to be inactive before we end the session and force a login.
    // The keep alive time is how often we want to refresh the access token to prevent it from going stale.
    const msPerMinute = 60000;
    const inactivityTimeInMinutes = 20 * msPerMinute; 
    const keepAliveTimeInMinutes = 5 * msPerMinute;
    inactivityTime(inactivityTimeInMinutes);
    keepAlive(keepAliveTimeInMinutes);
  };

  render() {
    const i18nProvider = locale => enMessages[locale];
    const { permissions } = this.props;
    // Note the last line: permissions !== 'admin' ? <Resource name="customReleases" icon={CustomReleaseIcon} /> : null,  I needed to provide a non user admin
    // with a trivial resource in order for it to display at all.
    return (
      <Admin
        loginPage={LoginPage}
        dataProvider={restClient}
        customRoutes={customRoutes}
        authProvider={authClient}
        locale="en"
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        menu={AppMenu}
      >
          { permissions => [
              permissions === 'admin' ? <Resource name="clients" list={ClientList} create={ClientCreate} edit={ClientEdit} icon={ClientIcon} /> : null,
              permissions === 'admin' ? <Resource name="releases" list={ReleaseList} create={ReleaseCreate} edit={ReleaseEdit} icon={ReleaseIcon} /> : null,
              permissions === 'admin' ? <Resource name="customReleases" create={CustomReleaseCreate} edit={CustomReleaseEdit} icon={CustomReleaseIcon} /> : null,
              permissions === 'admin' ? <Resource name="systemUsers" create={SystemUserCreate} edit={SystemUserEdit} list={SystemUserList} icon={SystemUserIcon} /> : null,
              permissions === 'admin' ? <Resource name="licenses" edit={LicenseEdit} create={LicenseCreate} /> : null,
              permissions === 'admin' ? <Resource name="activitiesView" list={ActivityList} /> : null,
              permissions === 'admin' ? <Resource name="activityTypes" /> : null,
              permissions !== 'admin' ? <Resource name="customReleases" icon={CustomReleaseIcon} /> : null,
          ]}
      </Admin>
    );
  };
};

const appTitleStyles = {
  whiteSpace        : 'nowrap',
  overflow          : 'hidden',
  WebkitTextOverflow: 'ellipsis',
  textOverflow      : 'ellipsis',
  margin            : 0,
  letterSpacing     : 0,
  fontSize          : 24,
  fontWeight        : '400',
  color             : 'rgb(255, 255, 255)',
  height            : 44,
  paddingTop        : 10,
  paddingBottom     : 10,
  WebkitFlex        : '1 1 0%',
  MsFlex            : '1 1 0%',
  flex              : '1 1 0%',
  backgroundColor   : 'white',
};

// Presently not implmented.  To implement, change the title in the App definition to: title={<AppTitle /> }.  Then, 
// modify the AppBar (will need to fork admin-on-rest), and pass along styles to the material-ui AppBar to set the background
// color to white.
const AppTitle = () => (
  <img style={appTitleStyles} src="./TACREDIT Logo 081006.jpg"/>
);

export default () => (
  <JssProvider generateClassName={generateClassName}>
      <App />
  </JssProvider>
);