import React, { Component } from 'react';
import withWidth from '@material-ui/core/withWidth';
import { AppBarMobile, GET_LIST, GET_MANY, WithPermissions } from 'react-admin';

import Welcome from './Welcome';
import AvailableLicenses from './AvailableLicenses';
import AvailableReleases from './AvailableReleases';
import RecentDownloads from './RecentDownloads';
import { restClient } from '../localRest';

const styles = {
  flex: { display: 'flex' },
  leftCol: { flex: 1 },
  rightCol: { flex: 2, marginLeft: '1em' },
  singleCol: { marginTop: '2em' },
  singleColIndented: { marginTop: '2em', marginLeft: '1em', marginRight: '1em' },
};

class Dashboard extends Component {
  state = {};

  getRecentDownloads = () => {
    restClient(GET_LIST, 'activitiesView', {
      filter: { activityType: 'Download' },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
    })
    .then(response => response.data)
    .then(recentDownloads => {
      this.setState({ recentDownloads });
    });
  }

  componentDidMount() {
    const systemUserRecord = localStorage.getItem('systemUserRecord');
    if (systemUserRecord !== null) {
      const systemUserObject = JSON.parse(systemUserRecord);
      const clientId = systemUserObject.clientId;

      this.getRecentDownloads();

      // Get the licenses
      restClient(GET_LIST, 'licenses', {
        pagination: { page: 1, perPage: 100 },
        filter: { clientId: clientId },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(response => response.data)
      .then(availableLicenses => {
        this.setState({ availableLicenses });
      });

      // See if there are any custom releases - if so, use them, otherwise use the standard releases
      restClient(GET_LIST, 'customReleases', {
        pagination: { page: 1, perPage: 100 },
        filter: { clientId: clientId },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(response => response.data)
      .then(availableReleases => {
        if (availableReleases.length > 0) {
          // Yes, at least one so use custom releases
          console.log('Using custom releases');
          this.setState({ availableReleases });
        } else {
          // No, none, so look for and use standard releases
          console.log('Using standard releases');
          restClient(GET_LIST, 'releases', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
          })
          .then(response => response.data)
          .then(availableReleases => {
            this.setState({ availableReleases });
          });
        }
      });
    }
  }

  downloadRequested = () => {
    // Force a redraw of the download dashboard
    this.getRecentDownloads();
  }

  render() {
    const {
      availableLicenses,
      availableReleases,
      recentDownloads,
    } = this.state;

    let title = "TAC CREDIT Support Portal";
    if (this.props.permissions === 'admin') {
      title += " - Admin Version";
    }

    return (
      <div>
        {this.props.width === 1 && <AppBarMobile title={title}/>}
        <Welcome title={title} />
        <div>
          <div style={styles.singleColIndented}>
          <WithPermissions>
            {({ permissions }) => permissions === 'admin'
              ? recentDownloads !== undefined ? <RecentDownloads recentDownloads={recentDownloads} /> : <div />
              : null
            }
            </WithPermissions>
          { availableReleases !== undefined ? <AvailableReleases releases={availableReleases} downloadRequested={this.downloadRequested} /> : <div /> }
          { availableLicenses !== undefined ? <AvailableLicenses licenses={availableLicenses} /> : <div /> }
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(Dashboard);
